import { useState, useEffect } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Form, Card, Col, Row, Spinner } from 'react-bootstrap';
import Button from 'components/base/Button';
import apiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useAppContext } from 'providers/AppProvider';

const currency = '₦';

function Deposit() {
  const [disableDeposit, setDisableDeposit] = useState(false);
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const { player } = useAppContext();

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  function getFlwConfig(transactionReference: string, amount: number) {
    return {
      public_key: 'FLWPUBK-09882be1f52c5eea6b596b0e7c2602a4-X',
      tx_ref: transactionReference,
      amount,
      currency: 'NGN',
      payment_options: 'card,mobilemoney,ussd',
      customer: {
        email: player.email,
        phone_number: player.phoneNumber,
        name: `${player.firstName} ${player.lastName}`
      },
      customizations: {
        title: 'Picks',
        description: 'Wallet Funding',
        logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg'
      }
    };
  }

  const handleDepositRequest = async () => {
    try {
      setDisableDeposit(true);
      const response = await apiService.requestDeposit(Number(depositAmount));
      const handleFlutterPayment = useFlutterwave(
        getFlwConfig(response.data.data, Number(depositAmount))
      );
      handleFlutterPayment({
        callback: response => {
          if (response.status === 'completed') {
            setSuccessMessage('We are processing your request');
            setDepositAmount('');
          } else {
            setErrorMessage('Transaction failed');
          }
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {}
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error funding wallet'
      );
    } finally {
      setDisableDeposit(false);
    }
  };

  return (
    <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-0">
      <Col xs={12} sm={10} xl={8}>
        <Row>
          <Col xs={12} sm={12} xl={12}>
            <div className="fs-6">Make a Deposit</div>
          </Col>
        </Row>
        <Card className="border border-200 auth-card">
          <Card.Body>
            <Form.Group as={Row} className="mt-5" size="lg">
              <Col xs={3} md={8} lg={4} className="fs-10">
                Deposit Amount:
              </Col>
              <Col xs={9} md={8} lg={4}>
                <Form.Control
                  size="sm"
                  placeholder={`${currency}50`}
                  onChange={e =>
                    setDepositAmount(
                      e.target.value && !isNaN(Number(e.target.value))
                        ? e.target.value
                        : ''
                    )
                  }
                  value={depositAmount}
                />
              </Col>
            </Form.Group>

            <Row className="mt-5 justify-content-center">
              <Button
                variant="secondary"
                onClick={handleDepositRequest}
                disabled={disableDeposit}
              >
                {disableDeposit ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Deposit'
                )}
              </Button>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Deposit;
