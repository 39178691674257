import classNames from 'classnames';
import { Navbar } from 'react-bootstrap';
import logo from 'assets/img/icons/logo.png';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';

const NavbarBrand = () => {
  const { breakpoints } = useBreakpoints();

  return (
    <>
      <div className="navbar-logo">
        {breakpoints.down('lg') && <NavbarToggleButton />}
        <Navbar.Brand
          as={Link}
          to="/"
          className={classNames({
            'me-1 me-sm-3': true
          })}
        >
          <div className="d-flex align-items-center">
            <img src={logo} alt="star-quest" width={27} />
            <p className="logo-text ms-2 d-none d-sm-block">Quest</p>
          </div>
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
