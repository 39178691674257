import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
  // other settings
});

export interface WagerPick {
  choice: number;
  timeSlot: string;
}

export interface Outcome {
  timeSlot: string;
  choice: string;
}

export interface Wager {
  amount: number;
  wagerPicks: Outcome[];
  status: string;
  payoutOnWin: number;
  created: Date;
}

export interface Transaction {
  pending: boolean;
  type: string;
  amount: number;
  created: Date;
}

export interface Bank {
  code: string;
  name: string;
}

export interface RegisterPlayer {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  accountNumber: string;
  bankCode: string;
  acceptTerms: boolean;
}

export default {
  async login(username: string, password: string) {
    return apiClient.post('player/login', { username, password });
  },
  async register(player: RegisterPlayer) {
    return apiClient.post('player/register', player);
  },
  async getPlayer() {
    return apiClient.get('player/');
  },
  async logout() {
    return apiClient.post('player/logout');
  },
  async getWagers() {
    return apiClient.get('player/bets');
  },
  async getPicks(date: string) {
    return apiClient.get(`picks?date=${date}`);
  },
  async getValidTimeSlots() {
    return apiClient.get('agent/valid-time-slots');
  },
  async bet(wager: { amount: number; wagerPicks: WagerPick[] }) {
    return apiClient.post('player/wager', wager);
  },
  async requestDeposit(amount: number) {
    return apiClient.post(`player/request/deposit?amount=${amount}`);
  },
  async withdraw(amount: number) {
    return apiClient.post(`player/request/withdrawal?amount=${amount}`);
  },
  async getYourWagers(date: string) {
    return apiClient.get(`player/wagers?date=${date}`);
  },
  async getTransactions(date: string) {
    return apiClient.get(`player/transactions?date=${date}`);
  },
  async getBanks() {
    return apiClient.get('banking/banks');
  },
  async forgotPassword(email: string) {
    return apiClient.post('player/forgot-password?email=' + email);
  },
  async resetPassword(password: string, token: string) {
    return apiClient.post(
      'player/reset-password?token=' + token + '&password=' + password
    );
  }
};
