import { useState, useEffect } from 'react';
import apiService, { Transaction } from '../../services/apiService';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import DatePicker from 'components/base/DatePicker';
import Button from 'components/base/Button';

function Transactions() {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      await getTransactions();
    };

    fetchTransactions();
  }, [date]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const response = await apiService.getTransactions(date);
      setTransactions(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error getting transactions'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-sm-0">
      <Col xs={12} sm={10} xl={8}>
        <Card className="border border-200 auth-card">
          <Card.Body>
            <Row className="g-3">
              <div
                className={classNames(
                  'position-relative px-lg-7 py-7 pb-sm-5 text-center text-md-center pb-lg-7'
                )}
              >
                <Row className="g-2">
                  <Col xs={12} md={8}>
                    <DatePicker
                      placeholder="Select a date"
                      onChange={(_dateAry, dateString) => setDate(dateString)}
                      options={{ dateFormat: 'Y-m-d' }}
                    />
                  </Col>
                  <Col xs={12} md={4} className="mt-2">
                    <Button variant="secondary" onClick={getTransactions}>
                      Refresh
                    </Button>
                  </Col>
                </Row>
                <Spinner
                  animation="border"
                  role="status"
                  className={classNames({
                    hide: !loading
                  })}
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>

                <div
                  className={classNames({
                    hide: loading
                  })}
                >
                  <Row className="mt-5 fw-bold">
                    <Col xs={4} md={4} lg={4}>
                      Time
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      Type
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      Amount (₦)
                    </Col>
                  </Row>
                  {transactions.map((transaction, index) => {
                    return (
                      <Row
                        key={index}
                        className={classNames('mt-5', {
                          'text-success':
                            (transaction.type === 'DEPOSIT' &&
                              !transaction.pending) ||
                            transaction.type === 'WIN',
                          'text-danger':
                            (transaction.type === 'WITHDRAWAL' &&
                              !transaction.pending) ||
                            transaction.type === 'BET',
                          'text-warning': transaction.pending
                        })}
                      >
                        <Col xs={4} md={4} lg={6}>
                          {new Date(transaction.created).toLocaleTimeString(
                            'en-US',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true
                            }
                          )}
                        </Col>
                        <Col xs={5} md={8} lg={6}>
                          {transaction.type}
                        </Col>
                        <Col xs={3} md={8} lg={6}>
                          {transaction.amount}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Transactions;
