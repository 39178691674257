import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    style: React.CSSProperties;
    className: string;
    'aria-labelledby': string;
  }
>(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={{ ...style, maxHeight: '30vh', overflowY: 'auto' }}
      className={className}
      aria-labelledby={labeledBy}
    >
      <Form.Control
        autoFocus
        className="mx-3 my-2 w-auto"
        placeholder="Search..."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        value={value}
      />
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter(
          child =>
            !value ||
            (React.isValidElement(child) &&
              typeof child.props.children === 'string' &&
              child.props.children
                .toLowerCase()
                .startsWith(value.toLowerCase()))
        )}
      </ul>
    </div>
  );
});

export default CustomMenu;
