import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { getColor, getItemFromStore } from 'helpers/utils';
import { Config, initialConfig } from 'config';
import { ACTIONTYPE, configReducer, SET_CONFIG } from 'reducers/ConfigReducer';
import {
  PLAYER_ACTION_TYPE,
  playerReducer,
  SET_PLAYER,
  CLEAR_PLAYER,
  defaultPlayer,
  Player
} from 'reducers/AuthenticatedUserReducer';

interface AppContextInterFace {
  config: Config;
  configDispatch: Dispatch<ACTIONTYPE>;
  toggleTheme: () => void;
  setConfig: (payload: Partial<Config>) => void;
  getThemeColor: (name: string) => string;
  player: Player;
  setPlayer: (payload: Partial<Player>) => void;
  clearPlayer: () => void;
  playerDispatch: Dispatch<PLAYER_ACTION_TYPE>;
}

export const AppContext = createContext({} as AppContextInterFace);

const AppProvider = ({ children }: PropsWithChildren) => {
  const configState: Config = {
    theme: getItemFromStore('theme', initialConfig.theme),
    openNavbarVertical: initialConfig.openNavbarVertical,
    navbarTopAppearance: getItemFromStore(
      'navbarTopAppearance',
      initialConfig.navbarTopAppearance
    )
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const [player, playerDispatch] = useReducer(playerReducer, defaultPlayer);

  const setConfig = (payload: Partial<Config>) => {
    configDispatch({
      type: SET_CONFIG,
      payload
    });
  };

  const toggleTheme = () => {
    configDispatch({
      type: SET_CONFIG,
      payload: {
        theme: config.theme === 'dark' ? 'light' : 'dark'
      }
    });
  };

  const getThemeColor = (name: string) => {
    return getColor(name);
  };

  const setPlayer = (payload: Partial<Player>) => {
    playerDispatch({
      type: SET_PLAYER,
      payload
    });
  };

  const clearPlayer = () => {
    playerDispatch({
      type: CLEAR_PLAYER
    });
  };

  useEffect(() => {
    document.documentElement.classList.add('navbar-horizontal');
  }, [config]);

  return (
    <AppContext.Provider
      value={{
        config,
        setConfig,
        toggleTheme,
        getThemeColor,
        configDispatch,
        player,
        setPlayer,
        clearPlayer,
        playerDispatch
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
