import { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import DatePicker from 'components/base/DatePicker';
import Button from 'components/base/Button';
import Rating from 'components/base/Rating';
import apiService, { Outcome } from '../../services/apiService';
import utils from '../../services/utils';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

function Outcomes() {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [outcomes, setOutcomes] = useState<Outcome[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const fetchOutcomes = async () => {
      await getOutcomes();
    };

    fetchOutcomes();
  }, [date]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
  }, [errorMessage]);

  const getOutcomes = async () => {
    try {
      setLoading(true);
      const response = await apiService.getPicks(date);
      setOutcomes(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error getting outcomes'
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className={classNames(
        'position-relative px-4 px-lg-7 py-7 pb-sm-5 text-center text-md-center pb-lg-7'
      )}
    >
      <Row className="g-2">
        <Col xs={12} md={8}>
          <DatePicker
            placeholder="Select a date"
            onChange={(_dateAry, dateString) => setDate(dateString)}
            options={{ dateFormat: 'Y-m-d' }}
          />
        </Col>
        <Col xs={12} md={4}>
          <Button variant="secondary" onClick={getOutcomes}>
            Refresh
          </Button>
        </Col>
      </Row>
      <Spinner
        animation="border"
        role="status"
        className={classNames({
          hide: !loading
        })}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div
        className={classNames({
          hide: loading
        })}
      >
        {outcomes.map((outcome, index) => {
          return (
            <Row
              key={index}
              className={classNames('mt-5', {
                display: !loading
              })}
            >
              <Col xs={5} md={4} lg={6}>
                {utils.convertTime(outcome.timeSlot)}
              </Col>
              <Col xs={7} md={8} lg={6}>
                <Rating
                  readonly
                  initialValue={utils.convertChoiceToNumber(outcome.choice)}
                />
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}

export default Outcomes;
