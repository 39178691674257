import { Button, Card, Dropdown, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useState } from 'react';
import apiService from '../../../services/apiService';
import { useAppContext } from 'providers/AppProvider';
import Modal from 'react-bootstrap/Modal';
import Terms from 'pages/app/Terms';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const { player } = useAppContext();

  const [navItems] = useState([
    {
      label: '08012345678',
      icon: 'phone'
    },
    {
      label: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/picks'
    }
  ]);
  const [modalShow, setModalShow] = useState(false);

  const handleSignOut = () => {
    apiService.logout();
    navigate('/sign-in', { replace: true });
  };

  return (
    <>
      <Dropdown.Menu
        align="end"
        className={classNames(
          className,
          'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
        )}
      >
        <Card className="position-relative border-0">
          <Card.Body className="p-0">
            <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
              <h6 className="text-black">
                {`${player.firstName} ${player.lastName}`}
              </h6>
              <p className="text-600 fs-8 mb-0 text-center">
                Account Balance <br />
                <span className="text-900">₦{player.accountBalance}</span>
              </p>
            </div>

            <div style={{ height: '6.5rem' }}>
              <Scrollbar>
                <Nav className="nav flex-column mb-2 pb-1">
                  <span className="text-1000 mx-3 fs-8">Reach us</span>
                  {navItems.map(item => (
                    <Nav.Item key={item.label}>
                      <Nav.Link
                        href={item.link}
                        className="px-3"
                        target="_blank"
                      >
                        <FeatherIcon
                          icon={item.icon}
                          size={16}
                          className="me-2 text-900"
                        />
                        <span className="text-1000">{item.label}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Scrollbar>
            </div>
          </Card.Body>
          <Card.Footer className="p-3 border-top">
            <div className="px-3">
              <Button
                className="btn btn-phoenix-secondary d-flex flex-center w-100"
                onClick={handleSignOut}
              >
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                Sign out
              </Button>
            </div>
            <div className="my-2 text-center fw-bold fs-10 text-600">
              <Link
                className="text-600 mx-1"
                to="#!"
                onClick={() => setModalShow(true)}
              >
                Terms and Conditions
              </Link>
            </div>
          </Card.Footer>
        </Card>
      </Dropdown.Menu>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Terms />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileDropdownMenu;
