import AuthCardLayout from 'layouts/AuthCardLayout';
import Button from 'components/base/Button';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DatePicker from 'components/base/DatePicker';
import Modal from 'react-bootstrap/Modal';
import Terms from '../app/Terms';
import apiService, { Bank } from 'services/apiService';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import CustomMenu from 'components/base/CustomMenu';
import CustomToggle from 'components/base/CustomToggle';

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [bank, setBank] = useState<Bank>({ code: '', name: 'Select a Bank' });
  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  useEffect(() => {
    const fetchBanks = async () => {
      await getBanks();
    };

    fetchBanks();
  }, []);

  const getBanks = async () => {
    try {
      const response = await apiService.getBanks();
      setBanks(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error getting banks'
      );
    }
  };

  const handleSignUp = async () => {
    if (!acceptTermsAndConditions) {
      setErrorMessage('You must accept the terms and conditions');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    const player = {
      username,
      password,
      firstName,
      lastName,
      email,
      phoneNumber,
      dateOfBirth,
      accountNumber,
      bankCode: bank.code,
      acceptTerms: acceptTermsAndConditions
    };

    try {
      setLoading(true);
      await apiService.register(player);
      setSuccessMessage('Player registered successfully');
      navigate('/sign-in');
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error registering player'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthCardLayout className="card-sign-up">
      <>
        <div className="text-center mb-7">
          <h3 className="text-1000">Sign Up</h3>
          <p className="text-700">Create your Star Quest account today</p>
        </div>
        <Form noValidate>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="firstName">First Name</Form.Label>
            <Form.Control
              id="firstName"
              type="text"
              placeholder="First Name"
              onChange={e => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="lastName">Last Name</Form.Label>
            <Form.Control
              id="lastName"
              type="text"
              placeholder="Last Name"
              onChange={e => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="username">Username</Form.Label>
            <Form.Control
              id="username"
              type="text"
              placeholder="Username"
              onChange={e => setUsername(e.target.value)}
            />
          </Form.Group>

          <Row className="g-3 mb-3">
            <Col sm={12} lg={6}>
              <Form.Group>
                <Form.Label htmlFor="password">Password</Form.Label>
                <Form.Control
                  id="password"
                  type="password"
                  placeholder="Password"
                  onChange={e => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={6}>
              <Form.Group>
                <Form.Label htmlFor="confirmPassword">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="email">Email address</Form.Label>
            <Form.Control
              id="email"
              type="email"
              placeholder="name@example.com"
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="text">Phone Number</Form.Label>
            <Form.Control
              id="phoneNumber"
              type="text"
              placeholder="08012345678"
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="email">Date of Birth</Form.Label>
            <DatePicker
              placeholder="Select a date"
              onChange={(_dateAry, dateString) => setDateOfBirth(dateString)}
              options={{ dateFormat: 'Y-m-d' }}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="accountNumber">Account Number</Form.Label>
            <Form.Control
              id="accountNumber"
              type="string"
              placeholder="0123456789"
              onChange={e => setAccountNumber(e.target.value)}
              // isInvalid={true}
            />
          </Form.Group>
          <Col sm={12} lg={12}>
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <Form.Group className="mb-3 text-start">
                  <Form.Label htmlFor="accountNumber">Bank</Form.Label>
                  <Form.Control
                    aria-label="Select a Bank"
                    value={bank.name}
                    readOnly
                  />
                </Form.Group>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                {banks.map(bank => (
                  <Dropdown.Item
                    eventKey={bank.code}
                    key={bank.code}
                    onClick={() => setBank(bank)}
                  >
                    {bank.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Form.Check
            type="checkbox"
            className="mb-3"
            required
            feedback="You must agree before submitting."
          >
            <Form.Check.Input
              type="checkbox"
              name="termsService"
              id="termsService"
              onChange={e => setAcceptTermsAndConditions(e.target.checked)}
            />
            <Form.Check.Label htmlFor="termsService" className="fs-9 text-none">
              I accept the{' '}
              <Link to="" onClick={() => setModalShow(true)}>
                terms and conditions
              </Link>
            </Form.Check.Label>
          </Form.Check>
          <Button
            variant="secondary"
            onClick={handleSignUp}
            disabled={loading}
            className="w-100 mb-3"
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Sign Up'
            )}
          </Button>
          <div className="text-center">
            <Link to={`/sign-in`} className="fs-9 fw-bold">
              Sign in to an existing account
            </Link>
          </div>
        </Form>
      </>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Terms />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthCardLayout>
  );
};

export default SignUp;
