import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import SignIn from 'pages/authentication/SignIn';
import App from 'App';
import Bet from 'pages/app/Bet';
import Deposit from 'pages/app/Deposit';
import Withdraw from 'pages/app/Withdraw';
import Transactions from 'pages/app/Transactions';
import Terms from 'pages/app/Terms';
import SignUp from 'pages/authentication/SignUp';
import Home from 'pages/app/Home';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import ResetPassword from 'pages/authentication/ResetPassword';
import Error404 from 'pages/error/Error404';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to="/sign-in" />
      },
      {
        path: '/terms',
        element: <Terms />
      },
      {
        path: '/sign-in',
        element: <SignIn />
      },
      {
        path: '/sign-up',
        element: <SignUp />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/reset-password/:token',
        element: <ResetPassword />
      },
      {
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            path: '/home',
            element: <Home />
          },
          {
            path: '/bet',
            element: <Bet />
          },
          {
            path: '/deposit',
            element: <Deposit />
          },
          {
            path: '/withdraw',
            element: <Withdraw />
          },
          {
            path: '/transactions',
            element: <Transactions />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
