import { useState, useEffect } from 'react';
import apiService, { WagerPick } from '../../services/apiService';
import { Form, Col, Row, Spinner } from 'react-bootstrap';
import utils from '../../services/utils';
import classNames from 'classnames';
import Rating from 'components/base/Rating';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

const currency = process.env.REACT_APP_CURRENCY_SYMBOL;

function Bet() {
  const [loading, setLoading] = useState(true);
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [ratings, setRatings] = useState<{ [key: string]: number }>({});
  const [betAmount, setBetAmount] = useState<number>(0);
  const [filteredRatings, setFilteredRatings] = useState<WagerPick[]>([]);
  const [disableBet, setDisableBet] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  useEffect(() => {
    const fetchTimeSlots = async () => {
      await getTimeSlots();
    };

    fetchTimeSlots();
  }, []);

  useEffect(() => {
    const result: WagerPick[] = [];
    for (const [k, v] of Object.entries(ratings)) {
      if (v > 0) {
        result.push({ timeSlot: k, choice: v - 1 });
      }
    }
    setFilteredRatings(result);
  }, [ratings]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  const getTimeSlots = async () => {
    try {
      setLoading(true);
      const response = await apiService.getValidTimeSlots();
      setLoading(false);
      setTimeSlots(response.data.timeSlots);
      const initialRatings: { [key: string]: number } = {};
      response.data.timeSlots.forEach((timeSlot: string) => {
        initialRatings[timeSlot] = 0;
      });
      setRatings(initialRatings);
    } catch (error) {
      console.error('Error getting time slots', error);
    }
  };

  const handleBet = async () => {
    try {
      if (betAmount < 50) {
        setErrorMessage('Minimum bet amount is 50');
        return;
      }
      if (Object.keys(filteredRatings).length < 1) {
        setErrorMessage('Please make a pick');
        return;
      }
      setDisableBet(true);
      await apiService.bet({ amount: betAmount, wagerPicks: filteredRatings });
      setSuccessMessage('Bet Successful, good luck');
      handleClearAll(); // todo include clearing amounts
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error betting'
      );
    } finally {
      setDisableBet(false);
    }
  };

  const handleClearAll = () => {
    timeSlots.forEach(timeSlot => {
      updateRating(timeSlot, 0);
    });
  };

  const updateRating = (timeSlot: string, rating: number) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [timeSlot]: rating
    }));
  };

  return (
    <>
      <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-0">
        <Col xs={12} sm={10} xl={12}>
          <Row>
            <Col xs={8} sm={10} xl={8}>
              <div className="fs-6">Make your Choices</div>
            </Col>
            <Col xs={4} sm={2} xl={4} className="text-end">
              <Button variant="link" onClick={handleClearAll}>
                Clear All
              </Button>
            </Col>
          </Row>

          <Spinner
            animation="border"
            role="status"
            className={classNames({
              show: loading,
              hide: !loading
            })}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div
            className={classNames('mt-3', {
              hide: timeSlots.length > 0 || loading
            })}
          >
            Bets can only be made between 9:00 AM and 9:00 PM. <br /> Please
            come back later.
          </div>
          <div
            className={classNames({
              show: !loading,
              hide: loading
            })}
          >
            {Object.entries(ratings).map(([timeSlot, rating], index) => {
              return (
                <Row
                  key={index}
                  className={classNames('mt-5', {
                    display: !loading
                  })}
                >
                  <Col
                    xs={4}
                    md={4}
                    lg={4}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {utils.convertTime(timeSlot)}
                  </Col>
                  <Col
                    xs={6}
                    md={6}
                    lg={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Rating
                      allowFraction={false}
                      initialValue={rating}
                      onClick={newRating => {
                        setRatings(prevRatings => ({
                          ...prevRatings,
                          [timeSlot]: newRating
                        }));
                      }}
                    />
                  </Col>
                  <Col xs={2} md={2} lg={2}>
                    <Button
                      variant="link"
                      className={classNames('p-0 text-secondary', {
                        hide: rating === 0
                      })}
                      onClick={() => updateRating(timeSlot, 0)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </div>

          <div>
            <Form.Group as={Row} className="mt-5">
              <Col xs={3} md={4} lg={4} className="fs-10">
                Bet Amount:
              </Col>
              <Col xs={6} md={4} lg={4}>
                <Form.Control
                  size="sm"
                  placeholder={`${currency}50`}
                  onChange={e =>
                    setBetAmount(
                      Number(e.target.value) ? Number(e.target.value) : 0
                    )
                  }
                />
              </Col>
              <Col xs={3} md={4} lg={4}>
                x {Math.pow(3, Object.keys(filteredRatings).length)}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
              <Col>Pay out:</Col>
              <Col>
                <Form.Control
                  size="sm"
                  readOnly
                  value={`${currency}${
                    Math.pow(3, Object.keys(filteredRatings).length) * betAmount
                  }`}
                />
              </Col>
            </Form.Group>

            <Row className="mt-3 justify-content-center">
              <Button
                variant="secondary"
                onClick={handleBet}
                disabled={disableBet}
              >
                {disableBet ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Bet'
                )}
              </Button>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Bet;
