export const SET_PLAYER = 'SET_PLAYER ';
export const CLEAR_PLAYER = 'CLEAR_PLAYER';

export interface Player {
  firstName: string;
  lastName: string;
  accountBalance: number;
  email: string;
  phoneNumber: string;
}

export const defaultPlayer: Player = {
  firstName: '',
  lastName: '',
  accountBalance: 0,
  email: '',
  phoneNumber: ''
};

//Action ts type
export type PLAYER_ACTION_TYPE =
  | { type: typeof SET_PLAYER; payload: Partial<Player> }
  | { type: typeof CLEAR_PLAYER };

export const playerReducer = (state: Player, action: PLAYER_ACTION_TYPE) => {
  switch (action.type) {
    case SET_PLAYER: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    case CLEAR_PLAYER:
      return {
        ...defaultPlayer
      };
    default:
      return state;
  }
};
