import { capitalize } from 'helpers/utils';
import { Nav } from 'react-bootstrap';
import { Route, routes } from 'sitemap';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { useAppContext } from 'providers/AppProvider';

interface NavItemProps {
  route: Route;
}

const NavItem = ({ route }: NavItemProps) => {
  const { pathname } = useLocation();
  const Icon = route.icon;
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();
  const { setConfig } = useAppContext();

  return (
    <Nav.Item as="li" className="dropdown">
      <Nav.Link
        className={classNames(
          'nav-link lh-1 d-flex align-items-center cursor-pointer',
          {
            'dropdown-toggle': breakpoints.up('lg'),
            'show text-primary': pathname === route.path
          }
        )}
        onClick={() => {
          setConfig({
            openNavbarVertical: false
          });
          navigate(route.path);
        }}
      >
        <Icon className="me-2" size={16} />
        <span>{capitalize(route.label)}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavbarTopNav = () => {
  return (
    <Nav className="navbar-nav-top pb-4 pb-lg-0 scrollbar">
      {routes.map(route => (
        <NavItem key={route.label} route={route} />
      ))}
    </Nav>
  );
};

export default NavbarTopNav;
