import { Dropdown, Nav } from 'react-bootstrap';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import FeatherIcon from 'feather-icons-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import apiService from '../../../services/apiService';

const NavItems = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setPlayer, clearPlayer } = useAppContext();

  useEffect(() => {
    getPlayer();
  }, [pathname]);

  const getPlayer = async () => {
    try {
      const response = await apiService.getPlayer();
      setPlayer(response.data);
    } catch {
      console.log("You're not logged in");
      clearPlayer();
      navigate('/sign-in', { replace: true });
    }
  };

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100" onToggle={getPlayer}>
          <Dropdown.Toggle
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant="link"
          >
            <FeatherIcon icon="user" size={32} className="me-2 text-900" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
