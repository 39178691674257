import { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import DatePicker from 'components/base/DatePicker';
import Button from 'components/base/Button';
import Rating from 'components/base/Rating';
import Badge from 'components/base/Badge';
import apiService, { Wager } from '../../services/apiService';
import utils from '../../services/utils';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Modal from 'react-bootstrap/Modal';

function Picks() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [wagerDate, setWagerDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [yourWagers, setYourWagers] = useState<Wager[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedWager, setSelectedWager] = useState<Wager | null>(null);

  useEffect(() => {
    const fetchYourWagers = async () => {
      await getYourWagers();
    };

    fetchYourWagers();
  }, [wagerDate]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  const getYourWagers = async () => {
    try {
      setLoading(true);
      const response = await apiService.getYourWagers(wagerDate);
      setYourWagers(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error getting outcomes'
      );
    } finally {
      setLoading(false);
    }
  };

  const wagerBadge = (status: string) => {
    if (status === 'WIN') {
      return (
        <>
          <Badge bg="success" variant="phoenix" className="ms-2 fs-10">
            WIN
          </Badge>
        </>
      );
    } else if (status === 'LOST') {
      return (
        <>
          <Badge bg="danger" variant="phoenix" className="ms-2 fs-10">
            Lost
          </Badge>
        </>
      );
    }
    return (
      <>
        <Badge bg="warning" variant="phoenix" className="ms-2 fs-10">
          Pending
        </Badge>
      </>
    );
  };

  const showWagerDetails = (wager: Wager) => {
    setSelectedWager(wager);
    setModalShow(true);
  };

  return (
    <>
      <div
        className={classNames(
          'position-relative px-lg-7 py-7 pb-sm-5 text-center text-md-center pb-lg-7'
        )}
      >
        <Row className="g-2">
          <Col xs={12} md={8}>
            <DatePicker
              placeholder="Select a date"
              onChange={(_dateAry, dateString) => setWagerDate(dateString)}
              options={{ dateFormat: 'Y-m-d' }}
            />
          </Col>
          <Col xs={12} md={4}>
            <Button variant="secondary" onClick={getYourWagers}>
              Get Bets
            </Button>
          </Col>
        </Row>
        <Spinner
          animation="border"
          role="status"
          className={classNames({
            hide: !loading
          })}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>

        <div
          className={classNames({
            hide: loading
          })}
        >
          <Row className="mt-5 fw-bold">
            <Col xs={4} md={4} lg={4}>
              Time
            </Col>
            <Col xs={4} md={4} lg={4}>
              Amount (₦)
            </Col>
            <Col xs={4} md={4} lg={4}>
              Status
            </Col>
          </Row>
          {yourWagers.map((wager, index) => {
            return (
              <Row
                key={index}
                className="mt-5"
                onClick={() => showWagerDetails(wager)}
              >
                <Col xs={4} md={4} lg={6}>
                  <a href="#!" className="text-decoration-none fw-bold fs-8">
                    {new Date(wager.created).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}
                  </a>
                </Col>
                <Col xs={3} md={6} lg={6}>
                  {wager.amount}
                </Col>
                <Col xs={5} md={6} lg={6}>
                  {wagerBadge(wager.status)}
                </Col>
              </Row>
            );
          })}
        </div>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedWager?.status}{' '}
            {selectedWager?.status === 'WIN'
              ? `₦ ${selectedWager?.payoutOnWin}`
              : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedWager?.wagerPicks.map((pick, index) => {
            return (
              <Row
                key={index}
                className={classNames('mt-5', {
                  display: !loading
                })}
              >
                <Col xs={5} md={4} lg={6}>
                  {utils.convertTime(pick.timeSlot)}
                </Col>
                <Col xs={7} md={8} lg={6}>
                  <Rating
                    readonly
                    initialValue={utils.convertChoiceToNumber(pick.choice)}
                  />
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Picks;
