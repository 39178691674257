import { Form, Card, Col, Row, Spinner } from 'react-bootstrap';
import Button from 'components/base/Button';
import apiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

const currency = '₦';

function Withdraw() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('');
  const [disableWithDrawal, setDisableWithdrawal] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  const handleWithDrawalRequest = async () => {
    try {
      setDisableWithdrawal(true);
      const response = await apiService.withdraw(Number(withdrawalAmount));
      setSuccessMessage(response.data.message);
      setWithdrawalAmount('');
      setSuccessMessage('We are processing your request');
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error withdrawing funds'
      );
    } finally {
      setDisableWithdrawal(false);
    }
  };

  return (
    <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-0">
      <Col xs={12} sm={10} xl={8}>
        <Row>
          <Col xs={12} sm={12} xl={12}>
            <div className="fs-6">Make a Withdrawal</div>
          </Col>
        </Row>
        <Card className="border border-200 auth-card">
          <Card.Body>
            <Form.Group as={Row} className="mt-5">
              <Col xs={3} md={8} lg={4} className="fs-10">
                Withdraw Amount:
              </Col>
              <Col xs={9} md={8} lg={4}>
                <Form.Control
                  size="sm"
                  placeholder={`${currency}50`}
                  onChange={e =>
                    setWithdrawalAmount(
                      e.target.value && !isNaN(Number(e.target.value))
                        ? e.target.value
                        : ''
                    )
                  }
                  value={withdrawalAmount}
                />
              </Col>
            </Form.Group>

            <Row className="mt-5 justify-content-center">
              <Button
                variant="secondary"
                onClick={handleWithDrawalRequest}
                disabled={disableWithDrawal}
              >
                {disableWithDrawal ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Withdraw'
                )}
              </Button>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Withdraw;
