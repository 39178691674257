import AuthCardLayout from 'layouts/AuthCardLayout';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import apiService from '../../services/apiService';
import { AxiosError } from 'axios';

const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  const handleResetPassword = async () => {
    // compare password and confirmPassword
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      if (params.token) {
        await apiService.resetPassword(password, params.token);
        setSuccessMessage('Password reset successfully');
        navigate('/sign-in');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error resetting password'
      );
    }
  };

  return (
    <AuthCardLayout>
      <div className="text-center mb-6">
        <h4 className="text-1000">Reset new password</h4>
        <p className="text-700">Type your new password</p>
        <Form className="mt-5">
          <Form.Control
            className="mb-2"
            id="password"
            type="password"
            placeholder="Type new password"
            onChange={e => setPassword(e.target.value)}
          />
          <Form.Control
            className="mb-4"
            id="confirmPassword"
            type="password"
            placeholder="Confirm new password"
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <Button
            variant="secondary"
            className="w-100"
            onClick={handleResetPassword}
          >
            Set Password
          </Button>
        </Form>
      </div>
    </AuthCardLayout>
  );
};

export default ResetPassword;
