function Terms() {
  return (
    <div>
      <h1>Terms and Conditions for Star Quest</h1>
      <p className="fs-8">
        Welcome to Star Quest, an online lottery program operated by Ess
        Lottery, based in Nigeria. Before you register and participate in Star
        Quest, please carefully read and understand the following terms and
        conditions. By accessing or using Star Quest, you acknowledge that you
        have read, understood, and agree to be bound by these terms and
        conditions. If you do not agree with any part of these terms, please
        refrain from using Star Quest.
      </p>
      <ol className="fs-8">
        <li>
          <h2>Eligibility:</h2>
          <ul>
            <li>
              To participate in Star Quest, you must be at least 18 years old
              the legal age of majority in Nigeria and be located in Nigeria.
            </li>
            <li>
              Employees of Ess Lottery and their immediate family members are
              not eligible to participate in Star Quest.
            </li>
          </ul>
        </li>
        <li>
          <h2>Registration:</h2>
          <ul>
            <li>
              To play Star Quest, users must register for an account on the Star
              Quest website or mobile application.
            </li>
            <li>
              During registration, users must provide accurate and complete
              information, including but not limited to their username, account
              number, bank code, email address, age, and phone numbers.
            </li>
            <li>
              Users are responsible for maintaining the confidentiality of their
              account information and for all activities that occur under their
              account.
            </li>
          </ul>
        </li>
        <li>
          <h2>Gameplay:</h2>
          <ul>
            <li>
              Star Quest allows players to predict the outcome between one and
              five every thirty minutes.
            </li>
            <li>
              Players must wager an amount of their choosing on their selected
              outcome.
            </li>
            <li>
              If a player's prediction is correct, they win the multiplier times
              the amount they wagered.
            </li>
            <li>
              Players can make multiple predictions within each thirty-minute
              period.
            </li>
          </ul>
        </li>
        <li>
          <h2>Data Storage and Privacy:</h2>
          <ul>
            <li>
              By participating in Star Quest, users consent to the storage and
              processing of their personal data as outlined in the Star Quest
              Privacy Policy.
            </li>
            <li>
              Star Quest collects and stores user data, including but not
              limited to usernames, account numbers, bank codes, email
              addresses, ages, and phone numbers.
            </li>
            <li>
              Star Quest uses collected data for the purpose of administering
              the lottery program and improving user experience.
            </li>
          </ul>
        </li>
        <li>
          <h2>Prohibited Conduct:</h2>
          <ul>
            <li>
              Users must not engage in any activity that violates these terms
              and conditions or applicable laws and regulations.
            </li>
            <li>
              Prohibited conduct includes but is not limited to fraud, tampering
              with Star Quest, or attempting to disrupt the normal operation of
              the program.
            </li>
          </ul>
        </li>
        <li>
          <h2>Termination of Account:</h2>
          <ul>
            <li>
              Ess Lottery reserves the right to suspend or terminate any user
              account at its discretion, without prior notice, if it believes
              the user has violated these terms and conditions or engaged in
              fraudulent activity.
            </li>
          </ul>
        </li>
        <li>
          <h2>Limitation of Liability:</h2>
          <ul>
            <li>
              Ess Lottery shall not be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              any way connected with the use of Star Quest.
            </li>
          </ul>
        </li>
        <li>
          <h2>Modification of Terms:</h2>
          <ul>
            <li>
              Ess Lottery reserves the right to modify these terms and
              conditions at any time, without prior notice. Users are encouraged
              to review the terms periodically for changes.
            </li>
          </ul>
        </li>
        <li>
          <h2>Governing Law:</h2>
          <ul>
            <li>
              These terms and conditions shall be governed by and construed in
              accordance with the laws of Nigeria, without regard to its
              conflict of law provisions.
            </li>
          </ul>
        </li>
        <li>
          <h2>Contact Information:</h2>
          <ul>
            <li>
              If you have any questions or concerns about these terms and
              conditions, please contact us at help@starquest.ng . By using Star
              Quest, you agree to abide by these terms and conditions. If you do
              not agree with any part of these terms, please refrain from using
              Star Quest. Thank you for your cooperation.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
}

export default Terms;
