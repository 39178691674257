import { Navbar } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavbarTopNav from './NavbarTopNav';

const NavbarTopHorizontal = () => {
  const {
    config: { navbarTopAppearance, openNavbarVertical },
    setConfig
  } = useAppContext();

  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-darker': navbarTopAppearance === 'darker'
      })}
      expand="lg"
      variant=""
    >
      <NavbarBrand />

      <Navbar.Collapse
        className="navbar-top-collapse order-1 order-lg-0 justify-content-center pb-0"
        in={openNavbarVertical}
      >
        <NavbarTopNav />
        <div
          className={classNames({ hide: !openNavbarVertical })}
          style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            zIndex: -1
          }}
          onClick={() => setConfig({ openNavbarVertical: false })}
        ></div>
      </Navbar.Collapse>
      <NavItems />
    </Navbar>
  );
};

export default NavbarTopHorizontal;
