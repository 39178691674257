export default {
  convertTime(time: string): string {
    const timeMap: { [key: string]: string } = {
      NINE_AM: '9:00 AM',
      NINE_THIRTY_AM: '9:30 AM',
      TEN_AM: '10:00 AM',
      TEN_THIRTY_AM: '10:30 AM',
      ELEVEN_AM: '11:00 AM',
      ELEVEN_THIRTY_AM: '11:30 AM',
      TWELVE_PM: '12:00 PM',
      TWELVE_THIRTY_PM: '12:30 PM',
      ONE_PM: '1:00 PM',
      ONE_THIRTY_PM: '1:30 PM',
      TWO_PM: '2:00 PM',
      TWO_THIRTY_PM: '2:30 PM',
      THREE_PM: '3:00 PM',
      THREE_THIRTY_PM: '3:30 PM',
      FOUR_PM: '4:00 PM',
      FOUR_THIRTY_PM: '4:30 PM',
      FIVE_PM: '5:00 PM',
      FIVE_THIRTY_PM: '5:30 PM',
      SIX_PM: '6:00 PM',
      SIX_THIRTY_PM: '6:30 PM',
      SEVEN_PM: '7:00 PM',
      SEVEN_THIRTY_PM: '7:30 PM',
      EIGHT_PM: '8:00 PM',
      EIGHT_THIRTY_PM: '8:30 PM',
      NINE_PM: '9:00 PM'
    };

    return timeMap[time] || time;
  },
  convertChoiceToNumber(choice: string): number {
    const choiceMap: { [key: string]: number } = {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5
    };

    return choiceMap[choice] || 0;
  }
};
