import React from 'react';

// The forwardRef is important!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }
>(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default CustomToggle;
