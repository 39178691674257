import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import Outcomes from './Outcomes';
import Picks from './Picks';
import Bet from './Bet';

function Home() {
  return (
    <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-sm-0">
      <Col xs={12} sm={10} xl={8}>
        <Card className="border border-200 auth-card">
          <Card.Body>
            <Tab.Container defaultActiveKey="bet">
              <Nav variant="underline" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="bet">Bet</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="outcomes">Outcomes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="your-picks">Your Stars</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="bet">
                  <Bet />
                </Tab.Pane>
                <Tab.Pane eventKey="outcomes">
                  <Outcomes />
                </Tab.Pane>
                <Tab.Pane eventKey="your-picks">
                  <Row className="g-3">
                    <Picks />
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Home;
