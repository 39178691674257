export type ThemeVariant = 'light' | 'dark';
export type NavPositionVariant = 'horizontal' | 'vertical' | 'combo' | 'dual';
export type NavTopShapeVariant = 'default' | 'slim';
export type NavbarAppearanceVariant = 'default' | 'darker';
export interface Config {
  theme: ThemeVariant;
  openNavbarVertical: boolean;
  navbarTopAppearance: NavbarAppearanceVariant;
  bodyClass?: string;
}

export const initialConfig: Config = {
  theme: 'light',
  openNavbarVertical: false,
  navbarTopAppearance: 'default'
};
