import AuthCardLayout from 'layouts/AuthCardLayout';
import Button from 'components/base/Button';
import { Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import apiService from 'services/apiService';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  const handleSend = async () => {
    try {
      setDisableButton(true);
      await apiService.forgotPassword(email);
      setSuccessMessage('Reset link sent successfully');
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error sending reset link'
      );
    } finally {
      setDisableButton(false);
    }
  };
  return (
    <AuthCardLayout>
      <div className={classNames('text-center mb-6')}>
        <h4 className="text-1000">Forgot your password?</h4>
        <p className="text-700 mb-5">
          Enter your email below and we will send <br className="d-sm-none" />
          you a reset link if you are registered.
        </p>
        <Form className="d-flex align-items-center mb-5">
          <Form.Control
            type="email"
            id="email"
            className="flex-1"
            placeholder="Email"
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            variant="secondary"
            onClick={handleSend}
            disabled={disableButton}
            className="ms-2"
            endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
          >
            {disableButton ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Send'
            )}
          </Button>
        </Form>
        <Link to="/sign-in" className="fs-9 fw-bold">
          Sign in
        </Link>
      </div>
    </AuthCardLayout>
  );
};

export default ForgotPassword;
