import {
  Icon,
  UilWallet,
  UilTransaction,
  UilMoneyWithdrawal,
  UilHome
} from '@iconscout/react-unicons';

export interface Route {
  label: string;
  icon: Icon;
  labelDisabled?: boolean;
  megaMenu?: boolean;
  active?: boolean;
  path: string;
}

export const routes: Route[] = [
  {
    label: 'Home',
    active: true,
    icon: UilHome,
    labelDisabled: false,
    path: '/home'
  },
  {
    label: 'Deposit',
    active: false,
    icon: UilWallet,
    labelDisabled: true,
    path: '/deposit'
  },
  {
    label: 'Withdraw',
    active: false,
    icon: UilMoneyWithdrawal,
    labelDisabled: true,
    path: '/withdraw'
  },
  {
    label: 'Transactions',
    active: false,
    icon: UilTransaction,
    labelDisabled: true,
    path: '/transactions'
  }
];
